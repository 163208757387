<template>
    <v-container fluid class="mt-8">
        <v-row>
            <v-col cols="6">

                <v-col cols="12">
                    <v-row cols="12">
                        <v-col cols="6">
                            <v-select :items="merchantOptions" v-model="idSelectedMerchant" @change="changeMerchant"
                                item-value="id" item-text="name" label="Merchant" />
                        </v-col>
                        <v-col cols="6">
                            <v-select :items="userOptions" v-model="idSelectedUser" @change="changeUser" item-value="id"
                                item-text="name" label="User" />
                        </v-col>
                    </v-row>
                    <v-row cols="12">
                        <v-col cols="4">
                            <v-select :items="roomOptions" v-model="roomCount" @change="changeRoom"
                                label="Total Room No." />
                        </v-col>
                        <v-col cols="4">
                            <v-col>
                                <span class="black--text">{{ ("Unread: " + noUnreadRoom) }}</span>
                                <span class="black--text">{{ (" Total: " + noRoomTotal) }}</span>

                            </v-col>



                        </v-col>
                        <v-col cols="2">
                            <v-select :items="['Unread', 'Total']" label="Type" v-model="typeUnread"
                                @change="onUnreadBoxPressed"></v-select>
                        </v-col>
                        <v-col cols="2">
                            <v-select :items="['Hide', 'Show']" label="Light me up" v-model="showLightMeUp"
                                @change="onShowLightMeUpPressed"></v-select>
                        </v-col>


                    </v-row>

                    <div v-for="(item, index) in room" :key="index" :class="['d-flex flex-row align-center my-2', null]">
                        <div class="d-flex flex-row justify-start">

                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on"
                                        @click="onChatRoomPressed(item.roomId)">mdi-chat</v-icon>
                                </template>
                                <span>{{ "Open" }}</span>
                            </v-tooltip>

                            <div style="background-color: #d1f0d2 ; margin-right:5px; padding: 10px; border-radius: 25px;">
                                <span class="mr-2 my-auto"> {{ getMerchantName(item) }} </span>
                                <span class="mr-2 my-auto"> {{ " > " + item.userProfile.name }} </span>
                                <span class="mr-2 my-auto"> {{ " (" + item.unreadCnt + ")" }} </span>
                            </div>

                        </div>
                        <span class="mr-2 my-auto"> {{ item.lastTime }} </span>

                    </div>

                </v-col>

            </v-col>
            <v-col cols="6">

                <v-row cols="12">


                    <v-col cols="6">

                        <v-select :items="messageOptions" v-model="messageCount" @change="changeMessage"
                            label="Message No." />
                    </v-col>
                    <v-col cols="6">
                        <div v-if="messageTotal == messageCount">
                            <span class="red--text">{{ "Message Count: " + messageTotal }}</span>
                        </div>
                        <div v-if="messageTotal != messageCount">
                            <span class="black--text">{{ "Message Count: " + messageTotal }}</span>
                        </div>


                    </v-col>


                </v-row>

                <v-col cols="12" v-for="[key, value] of Object.entries(chat).reverse()" :key="key">

                    <div v-for="(item, index) in value.reverse()" :key="index"
                        :class="['d-flex flex-row align-center my-2', item.from == 'user' ? 'justify-end' : null]">
                        <div class="d-flex flex-row justify-end" v-if="item.from == 'user'">
                            <span class="mr-2 my-auto"> {{ item.sendTime }} </span>
                            <div v-if="item.read == true">
                                <div
                                    style="background-color: #E5E4E2 ; margin-left:5px; padding: 10px; border-radius: 25px;">
                                    <span class="black--text">{{ item.msg }}</span>
                                </div>
                            </div>
                            <div v-if="item.read == false">
                                <div
                                    style="background-color: #ffcfcf ; margin-left:5px; padding: 10px; border-radius: 25px;">
                                    <span class="black--text">{{ item.msg }}</span>
                                </div>
                            </div>
                        </div>
                        <v-avatar :color="item.from == 'user' ? 'indigo' : 'red'" size="36">
                            <span class="white--text">{{ item.from[0] }}</span>
                        </v-avatar>

                        <div class="d-flex flex-row" v-if="item.from != 'user'">
                            <div v-if="item.read == true">
                                <div
                                    style="background-color: #E5E4E2 ; margin-left:5px; padding: 10px; border-radius: 25px;">
                                    <span class="black--text">{{ item.msg }}</span>
                                </div>
                            </div>
                            <div v-if="item.read == false">
                                <div
                                    style="background-color: #91abff ; margin-left:5px; padding: 10px; border-radius: 25px;">
                                    <span class="black--text">{{ item.msg }}</span>
                                </div>
                            </div>

                            <span class="ml-2 my-auto"> {{ item.sendTime }} </span>
                        </div>
                    </div>
                    <div class="d-flex flex-row justify-center">
                        <span class="my-auto">{{ key }}</span>
                    </div>
                </v-col>

            </v-col>
        </v-row>

    </v-container>
</template>
  
<script>
import { mapMutations, mapState } from 'vuex'

import api from '@/api'

export default {
    components: {
    },
    mounted() {
        this.getAllMerchantChatRoom()
    },
    computed: {
        ...mapState(['lang']),

    },
    watch: {},
    methods: {
        ...mapMutations(['PUSH_GLOBAL_SNACK_MESSAGES']),
        async getAllMerchantChatRoom() {
            this.loading = true
            try {
                var params = {
                    "roomNo": this.roomCount,
                };
                let result = await api.get('/message/listAdmin', { params });

                if (result && result.data && result.data != '') {
                    //console.log(result.data)
                    let tmpArr = []
                    let userArr = [];
                    let merchantArr = [];

                    result.data.forEach(item => {
                        try {


                            if (item && item.senderProfile && item.receiverProfile && item.messages && item.messages.length > 0) {
                                var merchantProfile;
                                var userProfile;
                                if (item.senderProfile.role == "merchant" || item.senderProfile.role == "admin") {
                                    merchantProfile = item.senderProfile;
                                    userProfile = item.receiverProfile;
                                } else {
                                    merchantProfile = item.receiverProfile;
                                    userProfile = item.senderProfile;
                                }


                                let lastTime = this.$moment(item.messages[0].createdAt).format('YYYY/MM/DD HH:mm')


                                tmpArr.push({
                                    merchantProfile: merchantProfile,
                                    merchantId: merchantProfile._id,
                                    userProfile: userProfile,
                                    userId: userProfile._id,
                                    lastTime: lastTime,
                                    unreadCnt: item.unreadCnt,
                                    roomId: item._id,
                                })

                                var optionUser = {
                                    "id": userProfile._id,
                                    "name": userProfile.name ?? "",
                                }
                                var isDuplicate0 = false;
                                merchantArr.forEach((e) => {
                                    if (e.id == userProfile._id) isDuplicate0 = true;
                                });
                                if (!isDuplicate0) userArr.push(optionUser)

                                var optionMerchant = {
                                    "id": merchantProfile._id,
                                    "name": merchantProfile.merchantProfile.name ?? "",
                                }
                                var isDuplicate1 = false;
                                merchantArr.forEach((e) => {
                                    if (e.id == merchantProfile._id) isDuplicate1 = true;
                                });
                                if (!isDuplicate1) merchantArr.push(optionMerchant)
                            }
                            // Your existing code inside the map function

                        } catch (error) {
                            console.error('Error processing item:', item, error);
                        }
                    });


                    //console.log(tmpArr)

                    //Sort array by name
                    merchantArr.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
                    merchantArr.unshift({ "id": "", "name": "All" });
                    userArr.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
                    userArr.unshift({ "id": "", "name": "All" });
                    //console.log(merchantArr)

                    this.userOptions = userArr;
                    this.merchantOptions = merchantArr;
                    this.room = tmpArr;
                    this.roomTotal = tmpArr;
                    this.updateRoomListByUnread();

                }
            } catch (error) {
                console.log(error)
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon: 'mdi-close',
                })
                this.loading = false
            }
            this.loading = false
        },
        onUnreadBoxPressed(input) {
            //console.log(input);
            this.typeUnread = input;
            this.updateRoomListByUnread();
        },
        onShowLightMeUpPressed(input) {
            //console.log(input);
            this.showLightMeUp = input;
            this.updateRoomListByUnread();
        },
        updateRoomListByUnread() {
            var newarr = [];
            var unreadArr = [];
            this.roomTotal.forEach(e => {
                if (e.unreadCnt > 0) {
                    unreadArr.push(e);
                }
                newarr.push(e);
            });
            if (this.typeUnread == "Unread") {
                this.room = unreadArr;
            } else {
                this.room = newarr;
            }
            this.noUnreadRoom = unreadArr.length;
            this.noRoomTotal = newarr.length;
            this.idSelectedMerchant = "";
            this.idSelectedUser = "";

            //Show/Hide Light me up
            if (this.showLightMeUp == "Hide") {
                var nextarr = [];
                var lid = "";
                this.merchantOptions.forEach(d => {
                    if (d["name"] == "Light me up") lid = d["id"]
                })
                this.room.forEach(e => {
                    if (lid != e["merchantId"]) {
                        nextarr.push(e);
                    }

                });
                this.room = nextarr;
            }



        },
        onChatRoomPressed(id) {
            this.idSelectedChatRoom = id;
            this.getAllMessageByID();
        },
        async getAllMessageByID() {
            this.loading = true
            try {
                var params = {
                    "messageNo": this.messageCount,
                };
                let result = await api.get('/message/conversationAdmin/' + this.idSelectedChatRoom, { params })

                if (result && result.data && result.data != '') {
                    //console.log(result.data)
                    var chatRoomDetail = result.data[0]
                    let tmpArr = []
                    var merchantId;
                    if (chatRoomDetail.senderProfile.role == "merchant") {
                        merchantId = chatRoomDetail.sender;
                    } else {
                        merchantId = chatRoomDetail.receiver;
                    }
                    if (chatRoomDetail.messages) {
                        chatRoomDetail.messages.map(item => {

                            let chatFrom = (item.from != merchantId) ? 'user' : 'Merchant'
                            let date = this.$moment(item.createdAt).format('YYYY/MM/DD')

                            if (!tmpArr[date]) {
                                tmpArr[date] = []
                            }

                            tmpArr[date].push({
                                from: chatFrom,
                                msg: item.content,
                                sendTime: this.$moment(item.createdAt).format('MM/DD HH:mm'),
                                read: item.read,
                            })
                        })
                    }

                    this.messageTotal = chatRoomDetail.messages.length;
                    //if(!this.isMessageAscending) tmpArr.reverse();
                    this.chat = tmpArr
                }
            } catch (error) {
                this.PUSH_GLOBAL_SNACK_MESSAGES({
                    message: error.response.data.message,
                    icon: 'mdi-close',
                })
                this.loading = false
            }
            this.loading = false
        },
        changeMerchant(id) {
            //console.log(id);
            var newarr = [];
            var noUnread = 0;
            this.roomTotal.forEach(e => {
                if (id == "") {
                    newarr.push(e);
                } else {
                    if (e.merchantId == id) {
                        newarr.push(e);
                        if (e.unreadCnt > 0) noUnread++;
                    }
                }
            });
            this.room = newarr;
            this.idSelectedMerchant = id;
            this.idSelectedUser = "";
            this.typeUnread = "Total";
            this.noUnreadRoom = noUnread;
            this.noRoomTotal = newarr.length;
        },
        changeUser(id) {
            //console.log(id);
            var newarr = [];
            var noUnread = 0;
            this.roomTotal.forEach(e => {
                if (id == "") {
                    newarr.push(e);
                } else {
                    if (e.userId == id) {
                        newarr.push(e);
                        if (e.unreadCnt > 0) noUnread++;
                    }
                }
            });
            this.room = newarr;
            this.idSelectedMerchant = "";
            this.idSelectedUser = id;
            this.typeUnread = "Total";
            this.noUnreadRoom = noUnread;
            this.noRoomTotal = newarr.length;
        },
        changeRoom(no) {
            //console.log(no);
            this.roomCount = no;
            this.getAllMerchantChatRoom();
        },
        changeMessage(no) {
            //console.log(no);
            this.messageCount = no;
            if (this.idSelectedChatRoom != "") {
                this.getAllMessageByID();
            }

        },
        onReverseMessagePressed() {
            //console.log(no);
            var isNew = this.isMessageAscending ? false : true;
            var newChat = this.chat;
            newChat.reverse();
            this.chat = newChat;
            this.isMessageAscending = isNew;
        },
        getMerchantName(item) {
            return item.merchantProfile && item.merchantProfile.merchantProfile
                ? item.merchantProfile.merchantProfile.name
                : item.merchantProfile.name;
        },

        /*
        <v-col cols="1">
                      
                      <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on" @click="onReverseMessagePressed">mdi-sort</v-icon>
                              </template>
                              <span>{{"Reverse"}}</span>
                          </v-tooltip>
                  </v-col>
        */

    },
    data: () => ({
        roomOptions: [20, 50, 100, 200, 500, 1000, 2000, 5000, 10000],
        roomCount: 200,
        messageOptions: [20, 50, 100, 200, 500, 1000, 2000, 5000, 10000],
        messageCount: 200,
        messageTotal: 0,
        room: [],
        noUnreadRoom: 0,
        noRoomTotal: 0,
        roomTotal: [],
        merchantOptions: [],
        userOptions: [],
        idSelectedUser: "",
        idSelectedMerchant: "",
        idSelectedChatRoom: "",
        isMessageAscending: true,
        isReverseMessage: true,
        typeUnread: "Unread",
        showLightMeUp: "Hide",
        chat: [],
        loading: false
    })
}
</script>


